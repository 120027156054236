import { FunctionComponent } from 'react';
import { Skeleton } from '@mui/material';
import { TableSkeletonWrapper } from './style';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

interface GridSkeletonProps {
  rows?: number;
  columns?: number;
}

const GridSkeleton: FunctionComponent<GridSkeletonProps> = ({rows = 5, columns= 5}) => {
  
  return (
    <TableSkeletonWrapper>
      <TableContainer>
        <Table sx={{ minWidth: 750 }}>
          <TableBody>
            {[...Array(rows)].map((_, index) => (
              <TableRow key={index}>
                {[...Array(columns)].map((_, index) => (
                  <TableCell key={index}><Skeleton variant="text" /></TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableSkeletonWrapper>
  );
};

export default GridSkeleton;
