import React, { FC, useMemo, createElement, useState } from 'react';
import { Box, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import Loader from 'elements/Loader';
import LearningPaths from 'pages/Dashboard/components/LearningPaths';
import Speechcrafter from 'pages/Dashboard/components/Speechcrafter';
import Feedback from 'pages/Dashboard/components/Feedback';
import Resources from 'pages/Dashboard/components/Resources';
import Achievements from 'pages/Dashboard/components/Achievements';
import SelfAssessments from 'pages/Dashboard/components/SelfAssessments';

// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import {
  DashoardContainer,
  MainContainer,
  GreetUser,
  TabLinksWrapper,
  TabPanelsWrapper,
} from 'pages/Dashboard/style';
import { Link, useHistory, useParams } from 'react-router-dom';
import { get, findIndex, map } from 'lodash';
import { getPlatformConfig, getPlatformName, getUserName } from 'utils/utility';

import useRoles from 'hooks/useRoles';
import { useIsSpeechCraftMFE } from 'hooks/useIsSpeechCraftMFE';
import { useGetProfileDetailQuery } from 'api/profileApi';
import { Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface TabPanelProps {
  children?: React.ReactNode;
  value: any;
  tabAriaControls: any;
  index: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, tabAriaControls, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={tabAriaControls}
      aria-labelledby={tabAriaControls}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Dashboard: FC<any> = () => {
  const { isCoordinator, isSpeechcrafter } = useRoles();
  const history = useHistory();

  const tabs = useMemo(
    () => [
      {
        ariaControls: 'pathlearning',
        label: 'Paths & Learning',
        id: 'path-learning',
        visibility: getPlatformConfig('config.showPathLearning'),
        component: LearningPaths,
      },
      {
        ariaControls: 'coordinatorHome',
        label: 'Coordinator Home',
        id: 'coordinator-home',
        visibility:
          getPlatformConfig('config.showCoordinatorHome') && isCoordinator,
        component: LearningPaths,
      },
      {
        ariaControls: 'speechcrafterHome',
        label: 'Learning',
        id: 'speechcrafter-home',
        visibility:
          getPlatformConfig('config.showSpeechcrafterHome') && isSpeechcrafter,
        component: LearningPaths,
      },
      {
        ariaControls: 'speechcrafters',
        label: 'Speechcrafters',
        id: 'speechcrafters',
        visibility: getPlatformConfig('config.speechcrafters') && isCoordinator,
        component: Speechcrafter,
      },
      {
        ariaControls: 'achievements',
        label: 'Achievements',
        id: 'achievements',
        visibility:
          !isCoordinator && getPlatformConfig('config.showAchievements'),
        component: Achievements,
      },
      {
        ariaControls: 'feedback',
        label:
          isSpeechcrafter || isCoordinator ? 'Messages & Feedback' : 'Feedback',
        id: 'feedback',
        visibility: getPlatformConfig('config.showFeedback'),
        component: Feedback,
      },
      {
        ariaControls: 'selfAssessments',
        label: 'Self-Assessments',
        id: 'self-assessments',
        visibility:
          getPlatformConfig('config.showSelfAssessments') && isSpeechcrafter,
        component: SelfAssessments,
      },
      {
        ariaControls: 'resources',
        label: 'Resources',
        id: 'resources',
        visibility: getPlatformConfig('config.showResources'),
        component: Resources,
      },
    ],
    [isCoordinator, isSpeechcrafter],
  );

  const routerParams = useParams<any>();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const handleDropdownChange = (event: any) => {
    history.push(`${getPlatformName()}/${event.target.value}`); // Navigate to the selected dropdown value
  };

  const user = getAuthenticatedUser();
  const { data: userData, isSuccess: userDataSuccess } =
    useGetProfileDetailQuery(user);

  const activeSection = get(
    routerParams,
    'activeSection',
    isSpeechCraftMFE
      ? isCoordinator
        ? 'coordinator-home'
        : 'speechcrafter-home'
      : 'path-learning',
  );

  const getActiveTabIndex = useMemo(() => {
    const index = findIndex(
      tabs.filter(tab => tab.visibility),
      tab => activeSection === tab.id,
    );

    if (index >= 0) {
      return index;
    }

    return 0;
  }, [activeSection, tabs]);

  const [expanded, setExpanded] = useState(false);
  const selectedTab = tabs.find(tab => tab.id === activeSection);

  const handleAccordionToggle = () => {
    setExpanded(prev => !prev);
  };

  const handleSelect = (value: string, label: string) => {
    history.push(`${getPlatformName()}/${value}`);
    setExpanded(false);
  };

  return (
    <DashoardContainer>
      <MainContainer>
        <GreetUser>
          {userDataSuccess && (
            <Typography variant='h1'>
              Welcome to {isSpeechCraftMFE && 'the Speechcraft Portal on '} Base
              Camp,
              <span> {getUserName(userData.user, true)}!</span>
            </Typography>
          )}
        </GreetUser>
        <TabLinksWrapper>
          <Box role="navigation" aria-label="content" sx={{ borderBottom: 2, borderColor: 'transparent' }}>
            <Tabs
              TabIndicatorProps={{
                children: <span className='MuiTabs-indicatorSpan' />,
              }}
              value={getActiveTabIndex}
              aria-label='dashboard tabs'
              selectionFollowsFocus
              sx={{ display: { xs: 'none', md: 'block'} }}
            >
              {map(
                tabs.filter(tab => tab.visibility),
                (tab, index) => (
                  <Tab
                    key={index}
                    aria-controls={tab.ariaControls}
                    label={tab.label}
                    component={Link}
                    to={`${getPlatformName()}/${tab.id}`}
                  />
                ),
              )}
            </Tabs>

            <Accordion 
              aria-label='dashboard tabs' 
              className='tabs-link-mobile' 
              expanded={expanded} 
              onChange={handleAccordionToggle}
              sx={{ display: { xs: 'block', md: 'none'} }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{selectedTab?.label || "Select Section"}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {map(
                  tabs.filter(tab => tab.visibility),
                  (tab, index) => (
                    <div className='tab-button' key={index}>
                      <Button
                        onClick={() => handleSelect(tab.id, tab.label)}
                        sx={{ mb: 1 }}
                        className={activeSection === tab.id ? 'active-link' : ''}
                      >
                        {tab.label}
                      </Button>
                    </div>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </TabLinksWrapper>
        <TabPanelsWrapper>
          {map(
            tabs.filter(tab => tab.visibility),
            (tab, index) =>
              createElement(TabPanel, {
                tabAriaControls: tab.ariaControls,
                value: getActiveTabIndex,
                key: index,
                index,
                children: createElement(tab.component),
              }),
          )}
        </TabPanelsWrapper>
      </MainContainer>
    </DashoardContainer>
  );
};

export default Dashboard;
