import React, { FC, useMemo, createElement, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import useRoles from 'hooks/useRoles';
import { Redirect, generatePath, useHistory } from 'react-router-dom';
import { DashboardRoutes as Routes } from 'routes';

import {
  DashoardContainer,
  MainContainer,
  GreetUser,
  TabLinksWrapper,
  TabPanelsWrapper,
} from 'pages/Dashboard/style';
import { Link, useParams } from 'react-router-dom';
import { get, findIndex, map } from 'lodash';
import { getPlatformName } from 'utils/utility';
import Requests from 'pages/Requests';
import MemberOverview from 'pages/BcmDashboard/components/MemberOverview';
import { REQUEST_TYPES } from 'config';
import GreetingUser from 'pages/BcmDashboard/components/GreetigUser';
import PathInProgress from 'pages/BcmDashboard/components/PathInProgress';
import RecentActivity from 'pages/BcmDashboard/components/RecentActivity';
import { useIsSpeechCraftMFE } from 'hooks';
import { Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface TabPanelProps {
  children?: React.ReactNode;
  value: any;
  tabAriaControls: any;
  index: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, tabAriaControls, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={tabAriaControls}
      aria-labelledby={tabAriaControls}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const BcmDashboard: FC<any> = () => {
  const { isBcm } = useRoles();
  const routerParams = useParams<any>();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const activeSection = get(routerParams, 'activeOption', 'approvals');

  const tabs = React.useMemo(
    () => [
      {
        ariaControls: 'approvals',
        label: 'Approvals',
        id: 'approvals',
        visibility: isBcm,
        component: Requests,
        disabled: false,
      },
      {
        ariaControls: 'memberoverview',
        label: 'Member Overview',
        id: 'member-overview',
        visibility: isBcm,
        component: MemberOverview,
      },
      {
        ariaControls: 'pathsinprogress',
        label: 'Paths in Progress',
        id: 'paths-progress',
        visibility: isBcm,
        component: PathInProgress,
      },
      {
        ariaControls: 'recentactivity',
        label: 'Recent Activity',
        id: 'recent-activity',
        visibility: isBcm,
        component: RecentActivity,
      },
    ],
    [isBcm],
  );

  const selectedTab = tabs.find(tab => tab.id === activeSection);

  const handleAccordionToggle = () => {
    setExpanded(prev => !prev);
  };

  const handleSelect = (value: string, label: string) => {
    history.push(`${getPlatformName()}/bcm-dashboard/${value}`);
    setExpanded(false);
  };

  const getActiveTabIndex = useMemo(() => {
    const index = findIndex(
      tabs.filter(tab => tab.visibility),
      tab => activeSection === tab.id,
    );

    if (index >= 0) {
      return index;
    }

    return 0;
  }, [activeSection, tabs]);

  if (!isBcm || isSpeechCraftMFE) {
    return (
      <Redirect
        to={generatePath(Routes.DASHBOARD.path, {
          activeSection: isSpeechCraftMFE
            ? 'coordinator-home'
            : 'path-learning',
        })}
      />
    );
  }

  return (
    <DashoardContainer className='bcm-dashbaord'>
      <MainContainer>
        <GreetUser>
          <GreetingUser />
        </GreetUser>
        <TabLinksWrapper>
          <Box role="navigation" aria-label="content" sx={{ borderBottom: 2, borderColor: 'transparent' }}>
            <Tabs
              TabIndicatorProps={{
                children: <span className='MuiTabs-indicatorSpan' />,
              }}
              value={getActiveTabIndex}
              aria-label='dashboard tabs'
              selectionFollowsFocus
              scrollButtons
              variant='scrollable'
              allowScrollButtonsMobile
              sx={{ display: { xs: 'none', md: 'block'} }}
            >
              {map(
                tabs.filter(tab => tab.visibility),
                (tab, index) => (
                  <Tab
                    key={index}
                    aria-controls={tab.ariaControls}
                    label={tab.label}
                    component={Link}
                    disabled={tab.disabled}
                    to={`${getPlatformName()}/bcm-dashboard/${tab.id}`}
                  />
                ),
              )}
            </Tabs>

            <Accordion 
              aria-label='dashboard tabs' 
              className='tabs-link-mobile' 
              expanded={expanded} 
              onChange={handleAccordionToggle}
              sx={{ display: { xs: 'block', md: 'none'} }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{selectedTab?.label || "Select Section"}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {map(
                  tabs.filter(tab => tab.visibility),
                  (tab, index) => (
                    <div className='tab-button' key={index}>
                      <Button
                        onClick={() => handleSelect(tab.id, tab.label)}
                        sx={{ mb: 1 }}
                        className={activeSection === tab.id ? 'active-link' : ''}
                      >
                        {tab.label}
                      </Button>
                    </div>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </TabLinksWrapper>
        <TabPanelsWrapper>
          {map(
            tabs.filter(tab => tab.visibility),
            (tab, index) =>
              createElement(TabPanel, {
                tabAriaControls: tab.ariaControls,
                value: getActiveTabIndex,
                key: index,
                index,
                children: createElement(tab.component, {
                  ...(tab.id === 'approvals' && {
                    requestType: REQUEST_TYPES.APPROVAL,
                  }),
                }),
              }),
          )}
        </TabPanelsWrapper>
      </MainContainer>
    </DashoardContainer>
  );
};

export default BcmDashboard;
