import {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  IconButton,
  Typography,
  Grid,
  SelectChangeEvent,
  Link,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { isEmpty, get, map, size } from 'lodash';
import classNames from 'classnames';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from 'elements/Loader';
import TextField from 'elements/Form/TextField';
import DropdownField from 'elements/DropdownField';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';

import { getUserName, getQueryParam } from 'utils/utility';
import { useAppContext } from 'contexts/AppContext';
import { ReactComponent as CompletionIcon } from 'assets/images/completion-icon.svg';

import {
  getInProgressCourses,
  updateFilters,
} from 'pages/BcmDashboard/components/PathInProgress/pathInProgressSlice';
import PathProgressModal from 'pages/BcmDashboard/components/PathInProgress/PathProgressModal';
import EmptyStateMessage from 'pages/BcmDashboard/components/MemberOverview/EmptyStateMessage';
import {
  Container,
  StickyDataGrid,
  SearchHolder,
} from 'pages/BcmDashboard/components/style';
import {
  SortedDescendingIcon,
  SortedAscendingIcon,
  UnsortedIcon,
} from 'pages/BcmDashboard/components/MemberOverview/index';
import { StyledSearchIcon } from 'components/Header/style';
import { scrollCellIntoView } from 'utils/utility';
import GridSkeleton from '../GridSkeleton';

const renderLevelCell = ({
  completed = 0,
  total = 0,
  approved = false,
} = {}) => {
  return approved ? <CompletionIcon /> : `${completed} of ${total}`;
};

const PathInProgress: FunctionComponent<any> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState<any>({});
  const [selectedClub, setSelectedClub] = useState('');
  const [searchText, setSearchText] = useState('');
  const [serachFilter, setSerachFilter] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState<any>([{}]);

  const dispatch = useDispatch();
  const { roles: userRoles = [] } = useAppContext();

  const {
    data = [],
    isLoading,
    isError,
    isFilterUpdated,
  } = useSelector((state: any) => state.inProgressCourses);

  const getNextData = useCallback(() => {
    const next = get(data, 'next', null);
    const { page }: any = getQueryParam(next || '');

    if (!next) {
      return;
    }

    dispatch(
      getInProgressCourses({ club: selectedClub, search: serachFilter, page }),
    );
  }, [data, dispatch, selectedClub, serachFilter]);

  const clubOptions = useMemo(
    () =>
      userRoles.reduce((acc: any, value: any) => {
        const { name, uuid, roles = [] } = value;

        const hasBcmRole = roles.some(({ isBcm }: any) => isBcm);

        if (!hasBcmRole) {
          return acc;
        }

        return [...acc, { label: name, value: uuid }];
      }, []),
    [userRoles],
  );

  const rows = useMemo(() => {
    return map(get(data, 'results', []), (value: any, index) => {
      const { user, pathName, progression } = value;
      const { level1, level2, level3, level4, level5, pathCompletion } =
        progression;

      return {
        ...value,
        id: index,
        name: getUserName(user),
        pathName,
        level1,
        level2,
        level3,
        level4,
        level5,
        pathCompletion,
      };
    });
  }, [data]);

  const onSortModelChange = useCallback(
    (newSortModel: any) => {
      const { field, sort } = sortModel[0] || {};
      if (isEmpty(newSortModel) && sort === 'desc') {
        setSortModel([{ field, sort: 'asc' }]);

        return;
      }

      setSortModel(newSortModel);
    },
    [sortModel],
  );

  useEffect(() => {
    if (clubOptions.length === 1) {
      setSelectedClub(clubOptions[0].value);
    }
  }, [clubOptions]);

  useEffect(() => {
    if (!selectedClub) {
      return;
    }

    dispatch(updateFilters());
    dispatch(
      getInProgressCourses({
        club: selectedClub,
        search: serachFilter,
        resetInitialState: true,
      }),
    );
  }, [dispatch, selectedClub, serachFilter]);

  const handleClubChange = (event: SelectChangeEvent) => {
    setSelectedClub(event.target.value);
    setSearchText('');
    setSerachFilter('');
  };

  const hadleUpdateTextSearch = (event: any) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleSearch = () => {
    if (isLoading) {
      return;
    }

    if (!searchText || searchText === serachFilter) {
      return;
    }

    setSerachFilter(searchText);
  };

  const handleClearSearch = () => {
    if (isLoading) {
      return;
    }

    setSearchText('');
    setSerachFilter('');
  };

  const handleCloseProgressModal = () => {
    setIsModalOpen(false);
    setSelectedPath({});
  };

  const handleOpenProgressModal = (selectedRow: any) => {
    setIsModalOpen(true);
    setSelectedPath(selectedRow);
  };

  const { user, pathName, name, courseId } = selectedPath;

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 138,
      renderCell: (params: any) => {
        return (
          <Link
            href='#'
            variant='body1'
            color='secondary'
            sx={{
              fontSize: '0.9rem',
            }}
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={() => handleOpenProgressModal(params.row)}
          >
            {params.row.name}
          </Link>
        );
      },
      renderHeader: () => <Typography variant='h4' component='h3'>Name</Typography>,
    },
    {
      field: 'pathName',
      headerName: 'Path',
      width: 138,
      renderHeader: () => <Typography variant='h4' component='h3'>Path</Typography>,
      renderCell: params => params.value || '--',
    },
    {
      field: 'level',
      headerName: 'Level 1',
      align: 'center',
      width: 138,
      sortable: false,
      renderHeader: () => <Typography variant='h4' component='h3'>Level 1</Typography>,
      renderCell: (params: any) => renderLevelCell(params.row.level1),
    },
    {
      field: 'level2',
      headerName: 'Level 2',
      align: 'center',
      width: 138,
      sortable: false,
      renderHeader: () => <Typography variant='h4' component='h3'>Level 2</Typography>,
      renderCell: (params: any) => renderLevelCell(params.row.level2),
    },
    {
      field: 'level3',
      headerName: 'Level 3',
      align: 'center',
      sortable: false,
      width: 138,
      renderHeader: () => <Typography variant='h4' component='h3'>Level 3</Typography>,
      renderCell: (params: any) => renderLevelCell(params.row.level3),
    },
    {
      field: 'level4',
      headerName: 'Level 4',
      sortable: false,
      align: 'center',
      width: 138,
      renderHeader: () => <Typography variant='h4' component='h3'>Level 4</Typography>,
      renderCell: (params: any) => renderLevelCell(params.row.level4),
    },
    {
      field: 'level5',
      headerName: 'Level 5',
      sortable: false,
      width: 138,
      align: 'center',
      renderHeader: () => <Typography variant='h4' component='h3'>Level 5</Typography>,
      renderCell: (params: any) => renderLevelCell(params.row.level5),
    },
    {
      field: 'pathCompletion',
      headerName: 'Path Completion',
      sortable: false,
      width: 138,
      align: 'center',
      renderHeader: () => <Typography variant='h4' component='h3'>Path Completion</Typography>,
      renderCell: (params: any) => {
        const { completed = 0, total = 0 } = params?.row?.pathCompletion || {};
        return `${completed} of ${total}`;
      },
    },
  ];

  return (
    <Box mb={10}>
      <Typography variant='h2'>Paths Currently in Progress</Typography>
      <Typography>
        The table below tracks members current path progress by indicating how
        many projects have been completed for each level. A green checkmark will
        appear when a level has been submitted by the member and approved by a
        Base Camp manager. Once a Path Completion has been submitted and
        approved, it will move to the Member Overview section. Click on the name
        of a member to see additional project details.
      </Typography>
      <Grid
        container
        mt={2}
        gap={3}
        alignItems='flex-end'
        justifyContent='space-between'
      >
        {size(clubOptions) > 1 && (
          <Grid item xs={12} sm={5}>
            <FormControlLabel
              label='Club'
              labelPlacement='top'
              id='club'
              control={
                <DropdownField
                  value={selectedClub}
                  options={clubOptions}
                  handleChange={handleClubChange}
                  placeholder='Select a Club'
                  disabled={isLoading}
                  inputProps={{'aria-labelledby': 'club'}}
                />
              }
            />
            {!selectedClub && (
              <Typography variant='body2' mt={4}>
                Please select a club from the dropdown menu above.
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        gap={3}
        alignItems='flex-end'
        justifyContent='space-between'
      >
        {selectedClub && (
          <Grid item xs={12} sm={5} mt={5}>
            <SearchHolder>
              <TextField
                inputProps={{
                  "aria-label": "Find record",
                }}
                placeholder='Search by Keyword'
                fullWidth
                value={searchText}
                onChange={hadleUpdateTextSearch}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') handleSearch();
                }}
                className={searchText ? 'has-data' : ''}
                disabled={isLoading}
              />
              {searchText && (
                <IconButton
                  onClick={() => handleClearSearch()}
                  className={classNames('clear-btn', { disable: isLoading })}
                  aria-label='Clear'
                >
                  <ClearIcon />
                </IconButton>
              )}
              <StyledSearchIcon
                aria-label='Search'
                onClick={(): void => {
                  handleSearch();
                }}
                className={classNames({ disable: isLoading || !searchText })}
              />
            </SearchHolder>
          </Grid>
        )}
        <Grid item xs={12} sm={5} className='text-end'>
          {/* <Button onClick={() => {}} color='ternary'>
                  <FileDownloadOutlinedIcon className='mr-5' />
                  Export Excel/CSV
                </Button> */}
        </Grid>
      </Grid>{' '}
      <Container>
        {isFilterUpdated && isLoading ? (
          <GridSkeleton rows={4} columns={8} />
        ) : !isEmpty(data?.results) && selectedClub && !isError ? (
          <InfiniteScroll
            dataLength={get(data, 'results', []).length}
            next={getNextData}
            hasMore={get(data, 'next', '') ? true : false}
            loader={
              <div className='loader' key={0}>
                <GridSkeleton columns={8} />
              </div>
            }
            scrollableTarget='scrollableDiv'
            style={{ overflow: 'unset' }}
          >
            <StickyDataGrid
              autoHeight
              getRowHeight={() => 'auto'}
              columns={columns}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              slots={{
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
                columnUnsortedIcon: UnsortedIcon,
              }}
              rows={rows}
              className={classNames('progress', {
                'disable-pagination': isLoading,
              })}
              aria-label="Path Currently In Progress Table"
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              hideFooter
              slotProps={{
                cell: {
                  'tabindex': '0',
                  onFocus: (event: any) =>
                    scrollCellIntoView(event.currentTarget),
                },
              }}
            />
          </InfiniteScroll>
        ) : (
          <EmptyStateMessage
            isSuccess={!isEmpty(data)}
            data={data?.results}
            isError={isError}
          />
        )}
      </Container>
      {isModalOpen && (
        <PathProgressModal
          username={user.username}
          handleClose={handleCloseProgressModal}
          title={`${pathName} Progress for ${name}`}
          className='modal-xl'
          courseId={courseId}
        />
      )}
    </Box>
  );
};

export default PathInProgress;
